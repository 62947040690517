<template>
  <div class="detailsPage">
    <div class="banner">
      <div style="width: 1136px;margin: 0 auto;text-align: left;
                  display: flex;margin-bottom: 30px;align-items: center;position: relative">
        <van-icon name="arrow-left" @click="goBack"/>
        <div class="aboutThis">{{ title }}</div>
        <collect style="position: absolute;right: 0px" :text-flg="true" :favorite="favorite"></collect>
      </div>

      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <div v-if="params.banner">
            <img :src="params.banner" alt="" srcset="" class="img">
          </div>
          <div v-else>
          </div>
        </van-swipe-item>
      </van-swipe>
      
      <el-row style="padding-top: 0px;width: 1136px;margin: 0 auto">
        <el-divider class="merchant-line" style="width: 98%;"></el-divider>
        <div style="display: flex; margin: 0 auto; margin-bottom: 15px;">
          <div class="plan-list" style="width: 900px;">
            <div style="display: flex">
              <div v-if="planUserList.length > 0" class="user-info-box" v-for="user in planUserList" :key="user.id">
                <img v-if="user.avatar" class="plan-user-avatar" :src="user.avatar">
                <img v-if="!user.avatar && user.userAvatarRandom" class="plan-user-avatar" :src="user.userAvatarRandom">
                <img v-if="!user.avatar && !user.userAvatarRandom" class="plan-user-avatar" src="../../assets/user_ic.png">
                <div class="plan-user-name">{{ user.userName }}</div>
              </div>
              <div v-if="planShowMoreFlg" @click="planMore()" style="font-size: 14px;color: #448CBB;font-weight: bold;cursor: pointer;width: 20px;">
                {{ $t("app.more_plan") }}
              </div>
              <div v-if="planUserList.length <= 0" style="font-size: 14px">
                {{ $t("app.come_plan") }}
              </div>
            </div>
          </div>
          <div style="width: calc(100% - 900px);padding-left: 8px;text-align: right">
            <div style="font-size: 14px">{{planTotal}}{{ $t("app.user_plan_go") }}</div>
            <el-button id="plango_advertorial" @click="addPlan()" v-if="planFlg == '0'" round class="btn-black">&nbsp;&nbsp;{{ $t("app.plan_go") }}&nbsp;&nbsp;</el-button>
            <el-button @click="cancelPlan()" v-if="planFlg == '1'" round class="btn-black">&nbsp;&nbsp;{{ $t("app.cancel") }}&nbsp;&nbsp;</el-button>
          </div>
        </div>
        <el-divider class="merchant-line" style="width: 98%;"></el-divider>
      </el-row>
    </div>
    <div v-if="type === '1'" class="areaContent" @click="toSearchClassify">{{ $t('app.showAllAreaContent') }}</div>
    <div class="detailRichText ql-editor" v-html="content"></div>
    <details-part0 :scenicList="districtList"></details-part0>
    <details-Part2 :scenicList="districtList"></details-Part2>
    <details-part1-pic :adv="adv"></details-part1-pic>
    <details-part3 :scenicList="districtList"></details-part3>

    <el-dialog
      center
      lock-scroll
      append-to-body
      width="500px"
      :visible.sync="signInVisible"
    >
      <sing-in-dialog :preview="signInVisible" @loginSuccess="loginSuccessCallback"/>
    </el-dialog>
  </div>
</template>
<script>
import {Swipe, SwipeItem} from 'vant';
import NavBar from "../../components/basicComponents/navBar";
import CategoryBar from "../../components/basicComponents/categoryBar";
import apis from "../../utils/apis";
import AdSpace from "../../components/adSpace";
import SearchBtn from "../../components/basicComponents/searchBtn";
import HomeBanner from "../../components/homeBanner";
import DetailsPart0 from "../../components/detailsComponents/detailsPart0";
import DetailsPart1Pic from "../../components/detailsComponents/detailsPart1Pic";
import DetailsPart2 from "../../components/detailsComponents/detailsPart2";
import DetailsPart3 from "../../components/detailsComponents/detailsPart3";
import DetailsPart1 from "../../components/detailsComponents/detailsPart1.vue"
import shop_time from "../../assets/img/shop_time.png"
import shop_tel from "../../assets/img/shop_tel.png"
import shop_mail from "../../assets/img/shop_mail.png"
import shop_home from "../../assets/img/shop_home.png"
import shop_loc from "../../assets/img/shop_loc.png"
import Collect from "../../components/collect"
import SingInDialog from "@/components/Login/SingInDialog.vue";
let {
  storeDeatil, 
  articleDetail,
  indexRecommend,
  addAdvertorialPlan,
  delAdvertorialPlan,
  getAdvertorialPlanList,
  addClick,
  } = apis
export default {
  components: {
    DetailsPart1Pic,
    DetailsPart3,
    DetailsPart2,
    DetailsPart0,
    DetailsPart1,
    HomeBanner,
    SearchBtn,
    AdSpace,
    CategoryBar,
    NavBar,
    Collect,
    SingInDialog,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      type: '',
      title:"",
      content: '',
      params:"",
      shopName: 'Tokyo Disneyland',
      params1:{},
      shop_time: shop_time,
      shop_tel: shop_tel,
      shop_mail: shop_mail,
      shop_home: shop_home,
      shop_loc: shop_loc,
      adv:[{
        img:'https://jpinbound.s3.ap-northeast-1.amazonaws.com/image/banner/0e64b3264fca4cfe97c79af50c311a54.JPG',
        name:'Tokyo Tower'
      }],
      districtList: [],
      scenicList: [],
      shopList: [],
      ticketList: [],
      travelStoryList: [],
      favorite:{
        width: 28,
        height: 28,
      },
      planTotal: 0,
      planUserList: [],
      planShowMoreFlg: false,
      planFlg: '0',
      signInVisible: false,
      planQueryParam: {
        pageNum: 1,
        pageSize: 20,
        advertorialId: this.$route.query.id,
        advertorialType: this.$route.query.type
      },
    };
  },
  watch: {
    $route(to, from) {
        window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
  mounted(){
    this.type = this.$route.query.type
    this.getDate(this.$route.query.id,this.$route.query.type)
    this.initData();
    this.getPlan();
  },
  created() {
  },
  methods: {
    loginSuccessCallback() {
      this.signInVisible = false;
    },
    click() {
      let params = {
        foreignId: this.$route.query.id,
        clickType: '0',
        domainUrl: window.location.host,
        clickUserId: localStorage.getItem('userId'),
      };
      addClick(params);
    },
    planMore() {
      this.planQueryParam.pageNum++;
      this.getPlan();
    },
    getPlan() {
      getAdvertorialPlanList(this.planQueryParam, (response) => {
        if (response.data) {
          this.planTotal = response.data.total;
          this.planShowMoreFlg = response.data.hasNextPage;
          if (response.data.pageNum === 1) {
            this.planUserList = response.data.list;
          } else {
            this.planUserList.push(...response.data.list);
          }
        }
      })
    },
    addPlan() {
      if (!localStorage.getItem('userId')) {
        this.signInVisible = true;
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        return;
      };
      const param = {
        advertorialId: this.$route.query.id,
        advertorialType: this.$route.query.type,
        userId: localStorage.getItem('userId'),
        userName: localStorage.getItem('name'),
        domainUrl: window.location.host,
      }
      addAdvertorialPlan({ ...param }, (response) => {
        if (response.code == 200) {
          this.planFlg = "1";
          this.$message({message: this.$t('app.add_plan_success'), customClass: 'messageIndex',type:'success'});
          this.planQueryParam.pageNum = 1;
          this.getPlan();
        }
      })
    },
    cancelPlan() {
      if (!localStorage.getItem('userId')) {
        this.signInVisible = true;
        this.$message({message: this.$t('app.plz_login'), customClass: 'messageIndex'});
        return;
      };
      const param = {
        advertorialId: this.$route.query.id,
        advertorialType: this.$route.query.type,
        userId: localStorage.getItem('userId'),
      }
      delAdvertorialPlan({ ...param }, (response) => {
        if (response.code == 200) {
          this.planFlg = "0";
          this.$message({message: this.$t('app.cancel_plan_success'), customClass: 'messageIndex',type:'success'});
          this.planQueryParam.pageNum = 1;
          this.getPlan();
        }
      })

    },
    initData() {
      //1-地区 2-景点 4-攻略 5-店铺 6-票 0-全部
      // this.getRecommendList('1')
      this.getRecommendList('2')
      // this.getRecommendList('4')
      // this.getRecommendList('5')
      // this.getRecommendList('6')

    },
    getDate(articleId,type) {
      let params = {
        articleId: articleId,
        userid: localStorage.getItem('userId'),
        type:type,
      }
      const _this = this
      if (this.type == '5') {
        const _this = this
        storeDeatil({...params}, data => {
          if (data.data.title) {
            this.click();
            this.title= data.data.title
            this.params=data.data
            this.params1=data.data.param
            this.districtList=data.data
            if(data.data.content) {
              this.content = data.data.content.replaceAll('<p>&nbsp;</p>',  '');
              this.content = this.content.replaceAll('<p><br></p>',  '');
              this.content = this.content.replaceAll('&nbsp;',  '');
              this.content = this.content.replaceAll('<p><strong></strong></p>',  '');
            }
            let removeFlag = -1
            if(_this.districtList.extra && _this.districtList.extra.length > 0){
              for(let i = 0 ;i < _this.districtList.extra.length; i++ ){
                if(_this.districtList.extra[i].title == '__mediabank_adv__'){
                  _this.adv = _this.districtList.extra[i]
                  removeFlag = i
                  break
                }
              }
              if(removeFlag>-1){
                _this.$delete(_this.districtList.extra,removeFlag)
              }
            }
          } else {
            this.$router.replace('/homePage');
          }
          
        });
      } else {
        articleDetail({...params}, data => {
          if (data.data.title) {
            this.click();
            this.params=data.data
            this.districtList=data.data
            this.title= data.data.title
            if(data.data.content) {
              this.content = data.data.content.replaceAll('<p>&nbsp;</p>',  '');
              this.content = this.content.replaceAll('&nbsp;',  '');
              this.content = this.content.replaceAll('<p><br></p>',  '');
              this.content = this.content.replaceAll('<p><strong></strong></p>',  '');
            }
            this.favorite = {
              favorite_id:articleId,
              type:type,
              userid: localStorage.getItem('userId'),
              favoriteFlg:data.data.param.favoriteFlg,
              width:28,
              height:28,
            }
            let removeFlag = -1;
            this.planFlg = data.data.param.planFlg;
            if(_this.districtList.extra && _this.districtList.extra.length > 0){
              for(let i = 0 ;i < _this.districtList.extra.length; i++ ){
                if(_this.districtList.extra[i].title == '__mediabank_adv__'){
                  _this.adv = _this.districtList.extra[i]
                  removeFlag = i
                  break
                }
              }
              if(removeFlag>-1){
                _this.$delete(_this.districtList.extra,removeFlag)
                // console.log("removeFlag = "+removeFlag)
              }
            }
          //   console.log(1111,this.districtList)
          } else {
            this.$router.replace('/homePage');
          }
          
        });
      }
    },
    getRecommendList(type) {
      let params = {
        type: type,
      }
      indexRecommend({ ...params }).then(res => {
        if (type == '1') {
          this.districtList = res.data.data
        } else if (type == '5') {
          this.shopList = res.data.data
        } else if (type == '2') {
          this.scenicList = res.data.data
        } else if (type == '6') {
          this.ticketList = res.data.data
        } else if (type == '4') {
          this.travelStoryList = res.data.data
        }
        // console.log(2222,this.districtList)
      });
    },
    goSearch(name, index) {
      this.$router.push({
        path: '/result',
        query: {
          name: name,
          index: index
        }
      })
      // console.log(name, index)
    },
    toSearchClassify() {
      this.$router.push({
        path: '/searchClassify',
        query: {
          title: this.title,
        }
      })
    },

    goBack() {
      this.$router.go(-1);
    }

  },


};
</script>

<style lang="less">
.merchant-line {
  margin-top: 8px !important;
}
.plan-list {
  overflow-x: hidden;
}

.plan-list:hover {
  overflow-x: auto;
}

.plan-list::-webkit-scrollbar {
  height: 8px;
  background-color: #f5f5f5;
}

.plan-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.plan-list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.plan-list::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.user-info-box {
  margin-right: 16px;
}
.plan-user-avatar {
  width: 50px;
  height: 50px !important;
  border-radius: 100%;
  border: 1px solid #e0e0e0;
  margin: 0 auto;
}
.plan-user-name {
  width: 60px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  font-size: 12px;
  position: relative;
}
.btn-black {
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.btn-black.el-button:active,
.btn-black.el-button:hover,
.btn-black.el-button:focus{
  color: #ffffff;
  border-color: #ffffff;
  outline: 0;
  background-color: #000000;
}
.detailsPage {
  background: white;
  margin-bottom: 80px;

  .banner {
    margin-top: 120px;
    .my-swipe {
      width: 1136px;
      margin: 0 auto;
    }
    img {
      height: 369px;
      display: block;
    }
    .van-icon {
      cursor: pointer;
      font-size: .35rem;
    }
  }

  .areaContent {
    font-size: .18rem;
    text-decoration: underline;
    text-align: left;
    width: 1136px;
    margin: .3rem auto 0;
    cursor: pointer;
  }

  .aboutThis {
    color: black;
    font-size: 0.3rem;
    font-weight: 600;
    display: inline-block;
    margin-left: 15px;
    width: 81%;
  }

  .detailRichText {
    text-align: start;
    width: 1136px;
    margin: .24rem auto;
    p{
      font-size: 0.2rem;
      color: #727171;
      img{
        margin: .2rem 0;
        width: 100%;
        object-fit: cover;
        display: block;
        height: 500px;
      }
      span{
        display: block;
        margin-bottom: .24rem;
      }
      strong {
        display: inline-block;
        width: 100%;
      }
    }
  }

  .shopName {
    font-size: 0.5rem;
    font-weight: 700;
    text-align: left;
    margin: 0.4rem 0 0.34rem 0.4rem;
  }

  .numberDiv {
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    font-size: 0.26rem;

    .eMail {
      width: 0.31rem;
      height: 0.31rem;
    }

    .number {
      color: #727171;
      margin-left: 0.2rem;
      text-align: left;
    }
  }
}
.img{
  object-fit: cover;
  width: 100%;
}
.my-swipe {
  cursor: initial !important;
}
</style>
